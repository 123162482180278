document.addEventListener("DOMContentLoaded", function () {
  // Nav functions
  document.addEventListener('click', function (event) {
    if (!event.target.matches('.mob-toggle, .mob-toggle svg, .mob-toggle g, .mob-toggle path')) return;
    event.preventDefault();
    document.querySelector('.nav-mobile-flyout').classList.toggle('active');
  }, false);

  document.addEventListener('click', function (event) {
    if (!event.target.matches('.close-contain, .close-contain img')) return;
    event.preventDefault();
    document.querySelector('.nav-mobile-flyout').classList.toggle('active');
  }, false);


  // Counter functionality:
  const counters = document.querySelectorAll('.counter-span');
  for(let n of counters) {
    const updateCount = () => {
      const target = + n.getAttribute('data-end');
      const count = + n.innerText;
      const speed = n.getAttribute('data-speed');
      const inc = target / speed; 

      if(count < target) {
        n.innerText = Math.ceil(count + inc);
        setTimeout(updateCount, speed);
      } else {
        n.innerText = target;
      }
    }
    updateCount();
  }

  // property brightness filter on title hover
  const propTitles = document.querySelectorAll('.prop-title h2');
  propTitles.forEach(title => {
    title.addEventListener('mouseover', (e) => {
      const imgTarget = e.target.parentNode.querySelector('img');
      imgTarget.style.filter = 'brightness(1)';
    });

    title.addEventListener('mouseleave', (e) => {
      const imgTarget = e.target.parentNode.querySelector('img');
      imgTarget.addEventListener('mouseleave', (e) => {
        e.target.style.filter = 'brightness(0.7)';
      });
    });
  });
  
  // property filter
  const citySelect = document.querySelector('#city-select');
  if(citySelect) {
    citySelect.addEventListener('input', function (event) {
      const targetCity = event.target.value;
      const propContainers = document.querySelectorAll('.prop-contain');
      propContainers.forEach(prop => {
        if (targetCity == 'All' || targetCity == '') {
          prop.style.display = 'flex';
        } else if (prop.dataset.city == targetCity) {
          prop.style.display = 'flex';
        } else {
          prop.style.display = 'none';
        }
      })
    }, false);
  }


  
  $('.slider').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    infinite: false,
    fade: true,
    autoplaySpeed: 3000
  });
  
  const switchNav = () => {
    const nav = document.querySelector('.nav');
    const navHeight = nav.clientHeight;
    const navLogo = document.querySelector('.nav .nav-logo');
    
    if(window.scrollY >= navHeight) {
      if(! nav.classList.contains('sticky')) {
        nav.classList.add('sticky');
        navLogo.src="/assets/navLogoRed.png";
      }
    } else {
      nav.classList.remove('sticky');
      navLogo.src="/assets/navLogo.png";
    }
  }

  document.addEventListener('scroll', (e) => {
    switchNav();
  });
  
  switchNav();
  
  // console.log(document.querySelector('.slider'));
  // const slider = document.querySelector('.slider')
  // slider.slick({});

  // const sliders = document.querySelectorAll('.slider');
  // console.log(sliders);
  // if(sliders) {
  //   sliders.forEach( slider => {
  //     slider.slick({
  //       dots: true,
  //       infinite: false,
  //       speed: 300,
  //       slidesToShow: 4,
  //       slidesToScroll: 4,
  //       responsive: [
  //         {
  //           breakpoint: 1024,
  //           settings: {
  //             slidesToShow: 3,
  //             slidesToScroll: 3,
  //             infinite: true,
  //             dots: true
  //           }
  //         },
  //         {
  //           breakpoint: 600,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 2
  //           }
  //         },
  //         {
  //           breakpoint: 480,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1
  //           }
  //         }
  //         // You can unslick at a given breakpoint now by adding:
  //         // settings: "unslick"
  //         // instead of a settings object
  //       ]
  //     })
  //   });
  // }

  // Footer functions
  const copyYear = document.querySelector('.footer .copy-year');
  if(copyYear) copyYear.innerHTML = new Date().getFullYear();
});
